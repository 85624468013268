import truncate from 'just-truncate';
import { Product } from '~/lib/data-contract/salesforce';
import { TProductDetails } from '~/shared/components/ProductCard/ProductCard.definition';
import { toBoolean } from '~/shared/utils/toBoolean';
import { TranslateFn } from '~/shared/utils/translation/hooks/useTranslation';

const getCTAButtonProductCard = (product: Product) => {
    const { primary, secondary } = product.ctaButtons || {};

    if (primary && !toBoolean(primary.isDisabled) && toBoolean(primary.shouldRender)) {
        return primary;
    } else if (secondary && !toBoolean(secondary.isDisabled) && toBoolean(secondary.shouldRender)) {
        return secondary;
    }
    return null; // Return null if no buttons meet the criteria
};

const getPotentialBonusEarning = (
    product: Product,
    translate: TranslateFn,
    convertPriceToLocale: (price: number) => string
) => {
    const bonusEarning = parseFloat(product.bonus || '0');
    if (bonusEarning) {
        return translate('pdp.bonusEarning', {
            amount: convertPriceToLocale(product.bonus),
        });
    }
    return 0; // Return 0 if there is no bonus or it's not parseable
};

export const mapRaptorProductData = (
    product: Product,
    translate: TranslateFn,
    convertPriceToLocale: (price: number) => string
) => {
    const ctaButtonProductCard = getCTAButtonProductCard(product);
    return {
        name: truncate(product?.productName ?? '', 26),
        longName: product.productName,
        potentialBonusEarning: getPotentialBonusEarning(product, translate, convertPriceToLocale),
        splash: product.splashText ? { text: product.splashText, variant: 'campaign' } : undefined,
        brand: { text: product.brandName }, // Assuming 'brand' is always present
        cust_synopsis: product.synopsis ?? null,
        cust_isNew: toBoolean(product.isNew ?? 'false'),
        cust_in_stock: toBoolean(product.inStock ?? 'false'),
        //@ts-ignore
        prices: product?.prices.priceList,
        id: product.productId ?? null,
        ctaButtons: {
            primary: product?.ctaButtons?.primary,
            secondary: product?.ctaButtons?.secondary,
        },
        images: [product.images],
        url: product.productUrl,
        label: product.synopsis,
        ctaButtonProductCard,
        infoTopRight: toBoolean(product.soleRepresentation ?? 'false')
            ? translate('pcard.soleRepresentation')
            : null,
        cust_sole_representation: toBoolean(product.soleRepresentation ?? 'false'),
        cust_yotpoAverageRating: parseFloat(product.yotpoAverageRating ?? '0'),
        cust_yotpoReviewCount: parseInt(product.yotpoReviewCount ?? '0', 0),
    } as TProductDetails; // Ensure TProductDetails is defined
};

export const isValidId = (value: unknown): value is string => typeof value === 'string' && !!value;
